import React from "react";
import { StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// Customizable Area End

import HashtagsController, {
  Props,
  HashtagAttributes
} from "./HashtagsController.web";

export class Hashtags extends HashtagsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { hashInputValue, selectedHashOption, hashData, selectedHashOptions } = this.state;
    return (
      //Merge Engine DefaultContainer
      <Paper className={classes.root} square elevation={0}>
        {/* Customizable Area Start */}
        {/* Merge Engine UI Engine Code */}
        <div className={classes.container}>
          <Autocomplete
            id="combo-box-demo"
            options={hashData}
            getOptionLabel={(option) => `# ${option.name}`}
            value={selectedHashOption}
            onChange={this.handleHashOptionChange}
            inputValue={hashInputValue}
            onInputChange={this.handleHashInputChange}
            renderInput={(params) => <TextField {...params} id='hashtags' name="hashtags" label="Search hashtags here .." variant="outlined" />}
            className={classes.autocomplete}
          />
        </div>
        <div className={classes.chipContainer}>
          {selectedHashOptions.length > 0 && selectedHashOptions.map((data: HashtagAttributes) => {
            let icon;
            return (
              <li key={data.id}>
                <Chip
                  icon={icon}
                  label={`# ${data.name}`}
                  onDelete={() => this.handleHashChipDelete(data)}
                  className={classes.chip}
                  data-testid="selectedHashCode"
                />
              </li>
            );
          })}
        </div>
        {/* Merge Engine UI Engine Code */}
        {/* Customizable Area End */}
      </Paper>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles: StyleRules = {
  root: {
    margin: '15px'
  },
  container: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px'
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '10px',
    margin: 0
  },
  chip: {
    margin: '10px',
  },
  autocomplete: {
    width: '400px'
  }
}

export default withStyles(styles)(Hashtags);
// Customizable Area End