// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import CreateComment from "../../blocks/comments/src/CreateComment";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Integrationwithzohoanalitics from "../../blocks/integrationwithzohoanalitics/src/Integrationwithzohoanalitics";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Share from "../../blocks/share/src/Share";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Maps from "../../blocks/maps/src/Maps";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Analytics from "../../blocks/analytics/src/Analytics";
import Calculator1 from "../../blocks/calculator1/src/Calculator1";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Verifierlist from "../../blocks/verifierlist/src/Verifierlist";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";


const routeMap = {
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Integrationwithzohoanalitics:{
 component:Integrationwithzohoanalitics,
path:"/Integrationwithzohoanalitics"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Share:{
 component:Share,
path:"/Share"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Maps:{
 component:Maps,
path:"/Maps"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Calculator1:{
 component:Calculator1,
path:"/Calculator1"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Verifierlist:{
 component:Verifierlist,
path:"/Verifierlist"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;